.menu {
    height: 0;
    overflow: hidden;
    background-color: white;
    width: 100%;
    z-index: 100;
    transition: height .25s ease-in-out;
    margin-top: -1px;
}

.menu__open {
    height: 100vh;
}

.menu__container {
    margin-top: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}