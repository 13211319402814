.fadeIn {
    width: 100%;
    
    position: relative;
}

.bold {
    font-weight: bold !important;
}

.fadeIn__title>span {
    position: absolute;
    right: 0px;
}

.fadeIn__title {
    border-bottom: 1px solid #276347;
}

.fadeIn__title:hover {
    cursor: pointer;
}

.fadeIn__title>span {
    transition: transform .5s ease-in-out;
}

.fadeIn__title__open {
    transform: rotate(-90deg);
}

.fadeIn__subComponent {
    height: 0;
    line-height: 0em;
    opacity: 0;
    transition: all .25s ease-in-out;
}

.fadeIn__show {
    line-height: 1em;
    opacity: 1;
    height: 100%;
}

.fadeIn__title {
    padding-bottom: 5px;
    position: relative;
    display: flex;
    align-items: center;
}