.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFPage {
  box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}

.react-pdf__Page__annotations {
  display: none;
}

.annotationLayer {
  display: none;
}