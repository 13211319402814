.awssld__bullets {
    bottom: 0px !important;
    z-index: 30;
}

.awssld__bullets button {
    background: white !important;
    width: 10px;
    height: 10px;
}

.awssld__bullets .awssld__bullets--active {
    background: #276347 !important;
    transform: scale(1, 1);
    width: 10px;
    height: 10px;
}

.awssld__content {
    background: none;
}

@media(max-width: 524px) {
    .modal-dialog {
        margin: 0px !important;
    }
    .modal-content {
        border: none;
    }
}