.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 100%;
    width: 100%;
}

.header__handler {
    background-color: white;
    height: 78px;
    top: 0px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
}

.header__menu:hover {
    cursor: pointer;
}

.header__menu__img__container:hover {
    cursor: pointer;
}

.header__menu {
    position: relative;
    height: 24px;
    width: 50px;
}

.header__select {
    width: 50px;
}

.header__menu__img__container {
    width: 80px;
    height: 70px;
    margin-bottom: 5px;
    cursor: pointer;
}

.header__menu__img {
    width: 100%;
    height: 100%;
}

.header__menu__stick {
    position: absolute;
    width: 18px;
    height: 2px;
    background-color: black;
}

.header__menu__stick:nth-child(1) {
    top: 2px;
}

.header__menu__stick:nth-child(2) {
    top: 8px;
}

.header__menu__stick:nth-child(3) {
    top: 13px;
}

.header__menu__stick {
    transition: all .2s ease-in-out;
}

.header__menu__stick__open:nth-child(2) {
    opacity: 0;
}

.header__menu__stick__open:nth-child(1) {
    top: 10px;
    transform: rotate(45deg);
}

.header__menu__stick__open:nth-child(3) {
    top: 10px;
    transform: rotate(-45deg);
}