.theme-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 100%;
    width: 100%;
}

.theme-header__handler {
    height: 78px;
    top: 0px;
}

.theme-header__menu {
    position: relative;
    height: 24px;
    width: 24px;
}

.theme-header__menu__img__container {
    width: 73px;
    height: 48px;
}

.theme-header__menu__img {
    width: 100%;
    height: 100%;
    cursor: initial;
}

.theme-header__menu__theme__stick {
    position: absolute;
    width: 18px;
    height: 2px;
}

.theme-header__menu__theme__stick:nth-child(1) {
    top: 2px;
}

.theme-header__menu__theme__stick:nth-child(2) {
    top: 8px;
}

.theme-header__menu__theme__stick:nth-child(3) {
    top: 13px;
}
