@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap);
@import url(https://cdn.jsdelivr.net/npm/instantsearch.css@7.4.5/themes/satellite-min.css);
html {
}

.fadeIn {
    width: 100%;
    
    position: relative;
}

.bold {
    font-weight: bold !important;
}

.fadeIn__title>span {
    position: absolute;
    right: 0px;
}

.fadeIn__title {
    border-bottom: 1px solid #276347;
}

.fadeIn__title:hover {
    cursor: pointer;
}

.fadeIn__title>span {
    transition: transform .5s ease-in-out;
}

.fadeIn__title__open {
    transform: rotate(-90deg);
}

.fadeIn__subComponent {
    height: 0;
    line-height: 0em;
    opacity: 0;
    transition: all .25s ease-in-out;
}

.fadeIn__show {
    line-height: 1em;
    opacity: 1;
    height: 100%;
}

.fadeIn__title {
    padding-bottom: 5px;
    position: relative;
    display: flex;
    align-items: center;
}
.menu {
    height: 0;
    overflow: hidden;
    background-color: white;
    width: 100%;
    z-index: 100;
    transition: height .25s ease-in-out;
    margin-top: -1px;
}

.menu__open {
    height: 100vh;
}

.menu__container {
    margin-top: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 100%;
    width: 100%;
}

.header__handler {
    background-color: white;
    height: 78px;
    top: 0px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
}

.header__menu:hover {
    cursor: pointer;
}

.header__menu__img__container:hover {
    cursor: pointer;
}

.header__menu {
    position: relative;
    height: 24px;
    width: 50px;
}

.header__select {
    width: 50px;
}

.header__menu__img__container {
    width: 80px;
    height: 70px;
    margin-bottom: 5px;
    cursor: pointer;
}

.header__menu__img {
    width: 100%;
    height: 100%;
}

.header__menu__stick {
    position: absolute;
    width: 18px;
    height: 2px;
    background-color: black;
}

.header__menu__stick:nth-child(1) {
    top: 2px;
}

.header__menu__stick:nth-child(2) {
    top: 8px;
}

.header__menu__stick:nth-child(3) {
    top: 13px;
}

.header__menu__stick {
    transition: all .2s ease-in-out;
}

.header__menu__stick__open:nth-child(2) {
    opacity: 0;
}

.header__menu__stick__open:nth-child(1) {
    top: 10px;
    transform: rotate(45deg);
}

.header__menu__stick__open:nth-child(3) {
    top: 10px;
    transform: rotate(-45deg);
}
.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFPage {
  box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}

.react-pdf__Page__annotations {
  display: none;
}

.annotationLayer {
  display: none;
}
.awssld__bullets {
    bottom: 0px !important;
    z-index: 30;
}

.awssld__bullets button {
    background: white !important;
    width: 10px;
    height: 10px;
}

.awssld__bullets .awssld__bullets--active {
    background: #276347 !important;
    transform: scale(1, 1);
    width: 10px;
    height: 10px;
}

.awssld__content {
    background: none;
}

@media(max-width: 524px) {
    .modal-dialog {
        margin: 0px !important;
    }
    .modal-content {
        border: none;
    }
}
.theme-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 100%;
    width: 100%;
}

.theme-header__handler {
    height: 78px;
    top: 0px;
}

.theme-header__menu {
    position: relative;
    height: 24px;
    width: 24px;
}

.theme-header__menu__img__container {
    width: 73px;
    height: 48px;
}

.theme-header__menu__img {
    width: 100%;
    height: 100%;
    cursor: auto;
    cursor: initial;
}

.theme-header__menu__theme__stick {
    position: absolute;
    width: 18px;
    height: 2px;
}

.theme-header__menu__theme__stick:nth-child(1) {
    top: 2px;
}

.theme-header__menu__theme__stick:nth-child(2) {
    top: 8px;
}

.theme-header__menu__theme__stick:nth-child(3) {
    top: 13px;
}

